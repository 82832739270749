<ng-container *ngIf="(installPromptData$ | async) as installPrompt">

  <div @fade *ngIf="isActiveAnimation" class="prompt-container d-flex align-items-center py-3 px-2">
    <svg-icon (click)="hidePrompt()" class="prompt-close-icon" name="close"></svg-icon>

    <div class="prompt-img__wrapper d-block">
      <img width="40" height="40" src="/assets/images/{{project}}/google-play-logo.png" alt="{{title}} logo">
    </div>

    <div class="prompt__content d-flex flex-column justify-content-center flex-grow-1">
      <h3 class="prompt__content-title font-weight-light m-0">{{title}}</h3>
      <span class="prompt__content-category font-weight-light">Entertainment</span>
    </div>

    <a *ngIf="project === '7days'" target="_blank" [href]="installPrompt?.downloadLink" class="prompt-btn font-weight-bold button button_no-shadow button_white text-uppercase">
      get
    </a>
    <ng-container *ngIf="project === 'ukcarp'">
      <a *ngIf="!isIos" (click)="hidePrompt()" routerLink="/install-app-for-android" class="prompt-btn font-weight-bold button button_no-shadow button_white text-uppercase">
        get
      </a>
      <a *ngIf="isIos" target="_blank" [href]="installPrompt?.downloadLink" class="prompt-btn font-weight-bold button button_no-shadow button_white text-uppercase">
        get
      </a>
    </ng-container>

  </div>
</ng-container>