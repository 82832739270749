<div class="ticket-card" [class.ticket-card--extend]="type === 'extend'" [class.ticket-card--win]="ticket.is_won" [class.ticket-card--loss]="ticket.is_won === false " [class.ticket-card--sold-out]="ticket.is_available === false">
  <div class="ticket-card__content">
    <ng-container *ngIf="ticket.is_won !== false; else noWinTmpl">
      {{ type === 'normal' ? ticket.ticket_number : ticket.prize_name}}
    </ng-container>
  </div>

  <div class="ticket-card__footer">{{ type === 'normal' ? ticket.is_available ? 'WIN NOW' : 'ALREADY WON' : ticket.ticket_number}}</div>
</div>

<ng-template #noWinTmpl>
   No win
</ng-template>
