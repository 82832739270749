export class Country {
  id: any;
  name: string;
  code: string;
  order: number;
  phone_prefix: string;

  constructor (id: string, name: string, code: string, order: number, phone_prefix: string) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.order = order;
    this.phone_prefix = phone_prefix;
  }
}

export class GeoData {
  ip: string;
  host: string;
  country_name: string;
  country_iso: string;
  time: number;
}

export const UK_COUNTRIES = ['GB', 'UA']; // ['GB', 'IE']

export type GeoCountry = '' | 'world' | 'uk';
