import { RaffleCompetitionsRequest } from '@core/models/raffle.model';
import {GetUserEngageKey} from '@core/services/userengage/userengagekey';

export let apiPath = function () {
    return (<any>window).ENVIRONMENT_PARAMETERS.api_path;
};

export let API_LIST = {
  login: (clearBasket: boolean = false) => {
      return apiPath() + '/api/v2/session' + (clearBasket ? '?clear_user_basket=yes' : '');
  },
  loginExternal: (token) => {
      return apiPath() + '/api/v2/login-token/' + token + '/session';
  },
  loginRemote: (type: string) => {
      return apiPath() + '/api/v2/remote-login/' + type;
  },
  passwordRecovery: () => {
      return apiPath() + '/api/v2/password-recovery-process';
  },
  passwordRecoveryInfo: (key) => {
      return apiPath() + '/api/v2/password-recovery-process/' + key;
  },
  passwordChange: (key) => {
      return apiPath() + '/api/v2/password-recovery-process/' + key + '/user';
  },
  passwordUpdate: () => {
      return apiPath() + '/api/v2/user/current/password';
  },
  currentSessionData: () => {
    return apiPath() + '/api/v2/session/current';
  },
  getSessionData: (id) => {
    return apiPath() + '/api/v2/session/' + id;
  },
  registration: () => {
      return apiPath() + '/api/v2/user';
  },
  update: () => {
    return apiPath() + '/api/v2/user/current';
  },
  updateCurrentUser: (type: string) => {
      switch (type) {
          case 'regular':
              return apiPath() + '/api/v2/users/current';
          case 'social':
              return  apiPath() + '/api/v2/users/current/social';
          default:
              throw new Error('updateCurrentUser type must be "regular" or "social". Current type used:' + type);
      }
  },
  getAvailableNotifications: () => {
      return apiPath() + '/api/v2/notification-types';
  },
  updateNotifications: () => {
      return apiPath() + '/api/v2/user/current/notifications';
  },
  singlePaymentStatus: (tid: string) => {
      return apiPath() + '/api/v2/user/current/detailed-payment/' + tid;
  },
  getOfferFromPayment: (tid: string) => {
      return apiPath() + '/api/v2/payment/' + tid + '/raffle-offers';
  },
  getOffersAndAddonsFromPayment: (tid: string) => {
      return apiPath() + '/api/v2/payment/' + tid + '/raffle-offers-and-addons';
  },
  getUserDetailedPayments: (processor: string) => {
      return apiPath() + '/api/v2/user/current/payments?processor=' + processor;
  },
  countries: () => {
      return apiPath() + '/api/v2/countries';
  },
  abTestEventSend: () => {
      return apiPath() + '/api/v2/ab-test/event';
  },
  abTestCreate: () => {
      return apiPath() + '/api/v2/ab-test';
  },
  smsUnsubscribe: () => {
    return `${apiPath()}/api/v2/sms-unsubscription`;
  },
  smsUnsubscribeFromKey: (key) => {
      return `${apiPath()}/api/v2/sms-unsubscription/${key}`;
  },
  getStaticContent: (slug) => {
      return apiPath() + '/api/v2/static-content/' + slug;
  },
  contactMessage: () => {
      return apiPath() + '/api/v2/contact-message';
  },
  postNewsletterEmail: (campaign: string|null) => {
      return apiPath() + '/api/v2/newsletter-email?campaign=' + encodeURIComponent(campaign)
        + '&userengageKey=' + GetUserEngageKey('');
  },
  getInstagramPost: (postId, cacheTime) => {
      return apiPath() + '/api/v2/instagram-post/' + postId + '?cacheTime=' + cacheTime;
  },
  getRaffleDrawResults: (type = [], offset = 0, limit = 0) => {
      return apiPath() + '/api/v2/raffle-draw-results?' + type.reduce((query, type) => `${query}type%5B%5D=${type}&`, '')
          + '&offset=' + offset
          + (limit > 0 ? ('&limit=' + limit) : '');
  },
  getPaymentCards(processor: string) {
      return apiPath() + '/api/v2/user/current/payment-cards/' + processor;
  },
  deletePaymentCards() {
      return `${apiPath()}/api/v2/user/current/payment-cards`;
  },
  postCartPayment(id: string, paymentValueValidation: any = null) {
    return apiPath() + '/api/v2/cart/' + id + '/payment?' + (paymentValueValidation !== null ? 'paymentValueValidation=' + paymentValueValidation : '');
  },
  getCartPendingPayments(id: string) {
    return apiPath() + '/api/v2/cart/' + id + '/pending-payments';
  },
  postCartOrder(id: string, paymentValueValidation: any = null) {
    return apiPath() + '/api/v2/cart/' + id + '/order?' + (paymentValueValidation !== null ? 'paymentValueValidation=' + paymentValueValidation : '');
  },
  postOrderPayment(id: string) {
    return apiPath() + '/api/v2/order/' + id + '/payment';
  },
  patchCartItem(cartId: string, itemId: string) {
      return apiPath() + `/api/v2/cart/${cartId}/item/${itemId}`;
  },
  deleteCartItem(cartId: string, itemId: string) {
      return apiPath() + `/api/v2/cart/${cartId}/item/${itemId}`;
  },
  getPaidGroupsUpcoming: () => { // Deprecated
      return apiPath() + '/api/v2/user/current/raffle-ticket-groups/paid/upcoming';
  },
  getPaidGroupsCurrent: () => {
      return apiPath() + '/api/v2/user/current/raffle-ticket-groups/paid/current';
  },
  getPaidGroupsHistorical: () => {
      return apiPath() + '/api/v2/user/current/raffle-ticket-groups/paid/historical';
  },
  getRaffleWinningNumbers: (limit: number = 1) => {
      return apiPath() + '/api/v2/raffle-winning-numbers?limit=' + limit;
  },
  postCartAddon(cartId: string, type: string, version: string) {
      return apiPath() + `/api/v2/cart/${cartId}/addon/${type}/${version}`;
  },
  deleteCartAddon(cartId: string, type: string, version: string) {
      return apiPath() + `/api/v2/cart/${cartId}/addon/${type}/${version}`;
  },
  deleteCartAddons(cartId: string) {
      return apiPath() + `/api/v2/cart/${cartId}/addons`;
  },
  getRefferals: (offset: number, limit: number) => {
      return apiPath() + `/api/v2/user/current/referrals?offset=${offset}&limit=${limit}`;
  },
  getCreditHistory: (offset: number, limit: number, includeSpends: boolean, includeEarnings: boolean) => {
      return apiPath() + `/api/v2/user/current/credit-history?offset=${offset}&limit=${limit}`
        + '&include_spends=' + (includeSpends ? 'true' : 'false')
        + '&include_earnings=' + (includeEarnings ? 'true' : 'false');
  },
  getCurrentDrawEntrants: () => {
      return apiPath() + '/api/v2/current-draw-counter/entrants';
  },
  getRaffleWinningNumberDraws: (offset: number, limit: number) => {
      return apiPath() + `/api/v2/raffle-winning-number-draws?offset=${offset}&limit=${limit}`;
  },
  getRaffleWinningNumberDrawGroups: (offset: number, limit: number) => {
      return apiPath() + `/api/v2/raffle-winning-number-draw-groups?offset=${offset}&limit=${limit}`;
  },
  getCategories: () => {
      return apiPath() + '/api/v2/raffle-categories';
  },
  getPageRaffle: (slug: string, currency: string) => {
      return apiPath() + '/api/v2/page-raffle-list/' + slug + '?currency=' + currency + '&campaign=' +  encodeURIComponent(window['gtmTrackedCampaignName'])
        + '&offset=0&limit=100';
  },
  getCategory: (slug: string) => {
    return apiPath() + '/api/v2/raffle-category/' + slug;
  },
  getPreviousWinners: (limit: number = 7) => {
    return apiPath() + '/api/v2/previous-winners?limit=' + limit;
  },
  postGeoData: () => {
    return apiPath() + '/api/v2/geo-data';
  },
  getUserDeletionReasons: () => {
    return apiPath() + '/api/v2/deletion-process-reasons';
  },
  postDeletionProcess: () => {
    return apiPath() + '/api/v2/user/current/deletion-process';
  },
  getDeletionProcess: () => {
    return apiPath() + '/api/v2/user/current/deletion-process';
  },
  deleteDeletionProcess: () => {
    return apiPath() + '/api/v2/user/current/deletion-process';
  },
  getRaffleDraws: (currency = 'GBP', categories = [], limit = 0, includeFinished = false, includeRunning = true, offset = 0, sortBy = '', filter = '') => {
    return apiPath()
      + '/api/v2/raffle-draws/' + currency + '?' + categories.map(slug => 'category%5B%5D=' + slug).join('&')
      + '&campaign=' + encodeURIComponent(window['gtmTrackedCampaignName']) + '&limit=' + limit + '&offset=' + offset
      + '&include_finished=' + (includeFinished ? 'true' : 'false')
      + '&include_running=' + (includeRunning ? 'true' : 'false')
      + '&include_soldout=' + (includeRunning ? 'true' : 'false')
      + '&sortby=' + sortBy + '&filter=' + filter
      ;
  },
  putPushToken: (type: string, pushToken: string) => {
    return apiPath() + '/api/v2/user/current/push-token/' + type + '/' + pushToken;
  },
  getDraws: ({currency = 'GBP', category = [], limit = 0, includeFinished = false, includeRunning = true, offset = 0, sortBy = ''}: RaffleCompetitionsRequest = {} ) => {
      return apiPath()
        + '/api/v2/raffle-draws/' + currency + '?' + category.map(slug => 'category%5B%5D=' + slug).join('&')
        + '&campaign=' + encodeURIComponent(window['gtmTrackedCampaignName']) + '&limit=' + limit + '&offset=' + offset
        + '&include_finished=' + (includeFinished ? 'true' : 'false')
        + '&include_running=' + (includeRunning ? 'true' : 'false')
        + '&sortby=' + sortBy
        ;
  },
  getRaffleWinningNumberDraw: (id: number) => {
      return apiPath() + `/api/v2/raffle-winning-number-draw/${id}`;
  },
  getRaffleWinningNumberDrawFromGameSlug: (slug: string) => {
      return apiPath() + `/api/v2/raffle-draw-game/${slug}/raffle-winning-number-draw`;
  },
  getRaffleWinningNumberDrawFromRaffleDrawId: (raffleDrawId: number) => {
      return apiPath() + `/api/v2/raffle-dated-draw/${raffleDrawId}/raffle-winning-number-draw`;
  },
  getRandomDrawFromWinningNumberDraw: (winningNumberDrawId: number) => {
      return apiPath() + `/api/v2/raffle-winning-number-draw/${winningNumberDrawId}/random-draw`;
  },
  getRaffleDrawPublicTickets: (slug) => {
      return apiPath() + '/api/v2/raffle-draw/' + slug + '/public-tickets';
  },
  getRaffleRealDrawPublicTickets: (drawId) => {
      return apiPath() + '/api/v2/raffle-real-draw/' + drawId + '/public-tickets';
  },
  getManualDraw: (slug) => {
      return apiPath() + '/api/v2/manual-draw/' + slug;
  },
  getPrizeDeliveryProcess: (id: string) => {
      return apiPath() + '/api/v2/prize-delivery-process/' + id;
  },
  patchPrizeDeliveryProcess: (id: string) => {
      return apiPath() + '/api/v2/prize-delivery-process/' + id;
  },
  getUserGameTicketsCount: (slug: string) => {
      return apiPath() + '/api/v2/user/current/game/' + slug + '/tickets-count';
  },
  getBrandStatistics: () => {
      return apiPath() + '/api/v2/brand-statistics';
  },
  getWinners: (offset: number = 0, limit: number = 6) => {
    return apiPath() + '/api/v2/winners?offset=' + offset + '&limit=' + limit;
  },
  getTeamMembers: () => {
    return apiPath() + '/api/v2/about-us-page';
  },
  getTeamMember: (slug: string) => {
    return apiPath() + '/api/v2/about-us-page/' + slug;
  },
  getFaqGroup: (slug: string) => {
    return apiPath() + '/api/v2/draw-faq-group/' + slug;
  },
  getWinner: (slug: string) => {
    return apiPath() + '/api/v2/winner/' + slug;
  },
  getRaffleResults: (offset = 0, limit = 6, categories: string[] = []): string => {
    return `${apiPath()}/api/v2/raffle-results?offset=${offset}&limit=${limit}&${categories.map(value => 'category[]=' + value).join('&')}`;
  },
  getDrawWinners: (offset = 0, limit = 6): string => {
    return `${apiPath()}/api/v2/draw-winners?offset=${offset}&limit=${limit}`;
  },
  getDrawResults: (offset: number = 0, limit: number = 6) => {
    return apiPath() + '/api/v2/draw-results?offset=' + offset + '&limit=' + limit;
  },
  getDrawResult: (slug: string) => {
    return apiPath() + '/api/v2/raffle-result/' + slug;
  },
  getRandomDrawWinners: (slug: string): string => {
    return `${apiPath()}/api/v2/raffle-result/${slug}/random-draw-winners`;
  },
  getRandomDrawWinnerExportLink: (slug: string, winnerId: number): string => {
    return `${apiPath()}/api/v2/raffle-result/${slug}/winner/${winnerId}/raffle-export-file/${slug}_${winnerId}.txt`;
  },

  getGameBySlug: (slug: string, currency: string, cartId: string = null, includeFinished: boolean = false) => {
    return apiPath() + `/api/v2/raffle-draw/${slug}/${currency}?include_finished=${(includeFinished ? 'true' : 'false')}&campaign=${encodeURIComponent(window['gtmTrackedCampaignName'])}${cartId ? '&cartId=' + cartId : ''}`;
  },
  getInstantWinGroupBySlug: (slug: string) => {
    return apiPath() + `/api/v2/raffle-draw/${slug}/instant-win-groups`;
  },
  getInstantWinTicketByGroup: (id: number, offset: number = 0, limit: number = 36) => {
    return apiPath() + `/api/v2/instant-win-group/${id}/instant-wins?limit=${limit}&offset=${offset}`;
  },
  getAddress: () => {
    return apiPath() + '/api/v2/user/current/address';
  },
  setAddress: () => {
    return apiPath() + '/api/v2/user/current/address';
  },
  updateMonthlySpendLimit: () => {
    return apiPath() + '/api/v2/user/current/monthly-spend-limit';
  },
  updateSpendLimit: () => {
    return apiPath() + '/api/v2/user/current/spend-limit';
  },
  updatePaymentBlockedPeriod: () => {
    return apiPath() + '/api/v2/user/current/payment-block';
  },
  getHeroSection: (slug: string) => {
    return apiPath() + '/api/v2/hero-section/' + slug;
  },
  getUserOrders: (offset = 0, limit = 36): string => {
    return `${apiPath()}/api/v2/user/current/orders/?offset=${offset}&limit=${limit}`;
  },
  getSimpleRaffleGroupsUpcoming: () => {
    return apiPath() + '/api/v2/user/current/simple-raffle-groups/upcoming';
  },
  getSimpleRaffleGroupsInstantWin: (slug) => {
    return `${apiPath()}/api/v2/user/current/raffle/${slug}/simple-raffle-group`;
  },
  getSimpleRaffleGroupsHistorical: () => {
    return apiPath() + '/api/v2/user/current/simple-raffle-groups/historical';
  },
  getUserOrderDetailedPayment: (id: string, queryKey: string) => {
    return apiPath() + '/api/v2/user/current/order/' + id + '/detailed-payment?key=' + queryKey;
  },
  getUserOrder: (id: string) => {
    return apiPath() + '/api/v2/user/current/order/' + id;
  },
  postCartItem(cartId) {
    return apiPath() + '/api/v2/cart/' + cartId + '/item?campaign=' + encodeURIComponent(window['gtmTrackedCampaignName']);
  },
  postCart() {
    return apiPath() + '/api/v2/cart';
  },
  getCart(id: string, forceDataRefresh: boolean = false) {
    return apiPath() + '/api/v2/cart/' + id + (forceDataRefresh ? '?refresh=true' : '');
  },
  postCartCoupon(cartId: string) {
    return apiPath() + '/api/v2/cart/' + cartId + '/coupon';
  },
  deleteCartCoupon(cartId: string, couponCode: string) {
    return apiPath() + '/api/v2/cart/' + cartId + '/coupon/' + encodeURIComponent(couponCode);
  },
  getCustomerReviews() {
    return apiPath() + '/api/v2/customer-reviews';
  },
  getSettings() {
    return apiPath() + '/api/v2/settings';
  },
  postResultUploadPromptFile(slug: string) {
    return apiPath() + '/api/v2/result-upload-prompt/' + slug + '/upload';
  },
  getResultUploadPrompt(slug: string) {
    return apiPath() + '/api/v2/result-upload-prompt/' + slug;
  },
  postUserUploadPromptFile(slug: string) {
    return apiPath() + '/api/v2/user-upload-prompt/' + slug + '/upload';
  },
  getUserUploadPrompt(slug: string) {
    return apiPath() + '/api/v2/user-upload-prompt/' + slug;
  },
  getPrizeAcceptance(slug: string) {
    return apiPath() + '/api/v2/prize-acceptance/' + slug;
  },
  patchPrizeAcceptance(slug: string) {
    return apiPath() + `/api/v2/prize-acceptance/` + slug;
  },
  getPrizeDelivery(slug: string) {
    return `${apiPath()}/api/v2/prize-delivery-link/${slug}`;
  },
  patchPrizeDelivery(slug: string) {
    return `${apiPath()}/api/v2/prize-delivery/${slug}`;
  },
  postPrizeDelivery(slug: string) {
    return `${apiPath()}/api/v2/prize-delivery-link/${slug}/upload`;
  },
  getPrizeDeliveryLink(orderId: string, queryKey: string) {
    return `${apiPath()}/api/v2/order/${orderId}/prize-delivery-link?key=${queryKey}`;
  },
  getPrizeDeliveryLinkDashboard(slug: string) {
    return `${apiPath()}/api/v2/user/current/raffle/${slug}/prize-delivery-link`;
  },
  getLiveStream() {
    return `${apiPath()}/api/v2/stream/active`;
  },
  getSingleStream(slug) {
    return `${apiPath()}/api/v2/stream/history/${slug}`;
  },
  getStreamList(offset: number = 0, limit: number = 6) {
    return `${apiPath()}/api/v2/stream/history?limit=${limit}&offset=${offset}`;
  },
  generateChatToken(streamId: number) {
    return `${apiPath()}/api/v2/stream/${streamId}/chat-token`;
  },
  sendChatMessage(streamId: number) {
    return `${apiPath()}/api/v2/stream/${streamId}/chat-message`;
  },
  deleteChatMessage(streamId: number, awsMessageId: string) {
    return `${apiPath()}/api/v2/stream/${streamId}/chat-message/${awsMessageId}`;
  },
  bannedChatUser(streamId: number, awsMessageId: string) {
    return `${apiPath()}/api/v2/stream/${streamId}/chat-message/${awsMessageId}/ban-chat-user`;
  },
  getChatMessages(streamId: number, offset: number = 0, limit: number = 100) {
    return `${apiPath()}/api/v2/stream/${streamId}/chat-messages?limit=${limit}&offset=${offset}`;
  },
  checkEmailMotoEvent(eventName: string) {
    return `${apiPath()}/api/v2/moto-event/${eventName}/signup-check`;
  },
  getUserFlags() {
    return `${apiPath()}/api/v2/user/current/flags`;
  },
  patchUserFlags() {
    return `${apiPath()}/api/v2/user/current/flags`;
  },
};
