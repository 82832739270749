import {Cart, CartItem} from '@core/models/cart.model';

export class SetCartData {
  static readonly type = '[Cart] SetCartData';
  constructor(public cart: Cart | null) { }
}

export class SetCartId {
  static readonly type = '[Cart] SetCartId';
  constructor(public id: string) { }
}

export class AddCartError {
  static readonly type = '[Cart] AddCartError';
  constructor(public error: string) { }
}

export class ClearCartErrors {
  static readonly type = '[Cart] ClearCartErrors';
}

export class UpdateCartItem {
  static readonly type = '[Cart] UpdateCartItem';
  constructor(public cartItem: CartItem) { }
}
