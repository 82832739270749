import { Action, State, Selector, StateContext, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {Cart, CartItem} from '@core/models/cart.model';
import { AddCartError, ClearCartErrors, SetCartData, SetCartId, UpdateCartItem } from '@store/cart/cart.actions';

export class CartStateModel {
  cart: Cart | null = null;
  id: string | null = null;
  cartInitiatedById = false;
  isInitiated = false;
  errors: string[] = [];
}

@State<CartStateModel>({
  name: 'cart',
  defaults: new CartStateModel(),
})

@Injectable()
export class CartState implements NgxsOnInit {
  @Selector()
  static cart(state: CartStateModel): Cart | null {
    return state.cart;
  }

  @Selector()
  static cartId(state: CartStateModel): string | null {
    return state.id;
  }

  @Selector()
  static itemsCount(state: CartStateModel): number | null {
    return state.cart ? state.cart.cached_item_count : null;
  }

  @Selector()
  static state(state: CartStateModel): CartStateModel {
    return state;
  }

  ngxsOnInit(ctx: StateContext<CartStateModel>): void {
    ctx.patchState({
      isInitiated: true,
    });
  }

  @Action(SetCartData)
  setCartData(ctx: StateContext<CartStateModel>, action: SetCartData): void {
    if (!action.cart) {
      ctx.patchState({
        cartInitiatedById: false,
        id: action.cart.id,
        cart: null,
      });
      return;
    }

    const errors = ctx.getState().errors ? [...ctx.getState().errors] : [];
    for (const error of action.cart.messages) {
      errors.push(error);
    }

    action.cart.items.forEach((item: CartItem) => {
      item.total_price = Number(item.total_price);
      item.reduced_price = Number(item.reduced_price);
    });

    ctx.patchState({
      cartInitiatedById: true,
      id: action.cart.id,
      cart: action.cart,
      errors: errors,
    });
  }

  @Action(SetCartId)
  setCartId(ctx: StateContext<CartStateModel>, action: SetCartId): void {
    ctx.patchState({
      cartInitiatedById: false,
      id: action.id,
    });
  }

  @Action(AddCartError)
  addCartError(ctx: StateContext<CartStateModel>, action: AddCartError): void {
    const errors = ctx.getState().errors ? [...ctx.getState().errors] : [];

    errors.push(action.error);

    ctx.patchState({
      errors: errors,
    });
  }

  @Action(ClearCartErrors)
  clearCartErrors(ctx: StateContext<CartStateModel>, action: ClearCartErrors): void {
    const errors = ctx.getState().errors;

    if (errors && errors.length) {
      ctx.patchState({
        errors: [],
      });
    }
  }

  @Action(UpdateCartItem)
  updateCartItem(ctx: StateContext<CartStateModel>, action: UpdateCartItem): void {

    const cart = ctx.getState().cart;
    const cartItems = cart.items.slice();
    const updatedCartItemIndex = cartItems.findIndex((item) => item.id === action.cartItem.id);
    cartItems[updatedCartItemIndex] = action.cartItem;

    ctx.patchState({
      cart: { ...cart, items: cartItems }
    });
  }
}
